<template>
  <div ref="container" :style="containerStyleBind" />
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from 'vue'
import { colorToLottie } from '../../libs/colors'
import lottie from 'lottie-web'

export default defineComponent({
  name: 'FlamingoLoader',
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    size: {
      type: [String, Number],
      default: 64,
    },
  },
  setup(props) {
    const regex = /^-?\d*\.?\d+$/
    const color = computed(() => props.color)
    const lottieColor = computed(() => colorToLottie(color.value))
    const animationData = computed(() => {
      return {
        v: '5.6.8',
        fr: 29.9700012207031,
        ip: 0,
        op: 50.0000020365418,
        w: 1080,
        h: 1080,
        nm: '02 Africa',
        ddd: 0,
        assets: [],
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'Layer 2 Outlines',
            parent: 3,
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11,
              },
              r: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.833],
                      y: [0.833],
                    },
                    o: {
                      x: [0.167],
                      y: [0.167],
                    },
                    t: 10,
                    s: [0],
                  },
                  {
                    i: {
                      x: [0.833],
                      y: [0.833],
                    },
                    o: {
                      x: [0.167],
                      y: [0.167],
                    },
                    t: 15,
                    s: [15],
                  },
                  {
                    i: {
                      x: [0.833],
                      y: [0.833],
                    },
                    o: {
                      x: [0.167],
                      y: [0.167],
                    },
                    t: 20,
                    s: [-15],
                  },
                  {
                    t: 25.0000010182709,
                    s: [0],
                  },
                ],
                ix: 10,
              },
              p: {
                a: 0,
                k: [737.656, 667.062, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [143.462, 180.85, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100, 100],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [12.622, -5.924],
                          [12.424, -2.971],
                          [0.066, -12.773],
                          [-5.644, -13.676],
                          [7.378, -15.17],
                          [-11.489, -12.351],
                          [-15.271, 9.684],
                          [15.552, 57.182],
                          [7.652, 7.904],
                          [10.091, -2.241],
                        ],
                        o: [
                          [-11.563, 5.427],
                          [-12.424, 2.971],
                          [-0.076, 14.795],
                          [6.435, 15.593],
                          [-7.378, 15.17],
                          [12.317, 13.24],
                          [50.046, -31.734],
                          [-2.888, -10.615],
                          [-7.004, -7.235],
                          [1.162, 12.464],
                        ],
                        v: [
                          [-5.101, -72.944],
                          [-42.465, -65.03],
                          [-67.994, -40.36],
                          [-45.829, -2.824],
                          [-55.715, 45.145],
                          [-56.973, 92.61],
                          [-7.144, 90.421],
                          [52.91, -64.386],
                          [38.049, -93.454],
                          [10.321, -103.609],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: lottieColor.value,
                      ix: 3,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4,
                    },
                    w: {
                      a: 0,
                      k: 30,
                      ix: 5,
                    },
                    lc: 2,
                    lj: 2,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: {
                      a: 0,
                      k: [143.462, 180.85],
                      ix: 2,
                    },
                    a: {
                      a: 0,
                      k: [0, 0],
                      ix: 1,
                    },
                    s: {
                      a: 0,
                      k: [100, 100],
                      ix: 3,
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7,
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4,
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5,
                    },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 150.000006109625,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Layer 1 Outlines 2',
            parent: 3,
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
              },
              p: {
                a: 0,
                k: [411.786, 443.355, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [411.786, 443.355, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100, 100],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [-11.259, 0.968],
                          [-49.772, 4.278],
                          [-12.165, -20.57],
                          [4.781, -21.915],
                          [-19.169, -11.647],
                          [-5.409, -14.171],
                          [-2.72, -9.3],
                          [-15.865, 4.297],
                          [-11.669, 11.576],
                          [37.651, -29.991],
                          [19.327, -20.442],
                          [6.371, -45.982],
                          [34.75, -33.286],
                          [43.413, 20.755],
                          [15.718, 24.623],
                          [3.233, 18.154],
                          [-12.271, 13.763],
                          [-5.223, 17.141],
                          [8.368, 21.747],
                          [-0.751, 23.955],
                          [23.507, 4.669],
                          [13.428, -3.797],
                          [12.747, -5.678],
                          [29.12, 22.929],
                          [-22.861, 29.174],
                          [-10.365, 12.669],
                          [-7.851, 26.19],
                          [-27.282, -1.808],
                          [-10.093, -2.827],
                          [-17.24, 23.479],
                          [-9.824, 8.877],
                          [-7.446, -10.949],
                          [6.568, -9.478],
                          [-0.821, -11.502],
                          [-10.76, -3.454],
                        ],
                        o: [
                          [49.772, -4.278],
                          [23.809, -2.046],
                          [-22.28, 2.604],
                          [-4.783, 21.915],
                          [12.963, 7.877],
                          [3.456, 9.052],
                          [4.614, 15.776],
                          [15.865, -4.297],
                          [14.421, 45.925],
                          [-22.005, 17.527],
                          [-31.892, 33.732],
                          [-6.605, 47.665],
                          [-34.751, 33.286],
                          [-26.355, -12.6],
                          [-9.922, -15.543],
                          [-3.234, -18.155],
                          [11.925, -13.375],
                          [6.792, -22.29],
                          [-8.607, -22.368],
                          [0.751, -23.955],
                          [-9.815, -9.92],
                          [-13.428, 3.797],
                          [-33.858, 15.079],
                          [-29.12, -22.929],
                          [10.096, -12.884],
                          [17.314, -21.162],
                          [7.851, -26.19],
                          [10.459, 0.693],
                          [28.049, 7.857],
                          [7.837, -10.672],
                          [9.824, -8.877],
                          [6.485, 9.535],
                          [-6.568, 9.478],
                          [0.805, 11.272],
                          [10.758, 3.452],
                        ],
                        v: [
                          [5.091, -269.271],
                          [154.407, -282.103],
                          [219.445, -263.097],
                          [177.818, -219.579],
                          [202.665, -161.616],
                          [238.696, -135.656],
                          [241.813, -106.901],
                          [282.413, -88.719],
                          [322.366, -116.615],
                          [285.685, 11.953],
                          [216.337, 59.638],
                          [178.702, 191.021],
                          [120.048, 319.959],
                          [-12.795, 347.6],
                          [-74.179, 286.682],
                          [-96.338, 236.391],
                          [-84.018, 184.385],
                          [-47.023, 146.46],
                          [-70.943, 82.877],
                          [-72.167, 11.624],
                          [-115.052, -46.183],
                          [-154.299, -53.29],
                          [-192.396, -36.082],
                          [-297.823, -44.852],
                          [-313.925, -143.673],
                          [-278.591, -177.655],
                          [-251.236, -254.24],
                          [-196.084, -306.273],
                          [-165.925, -297.704],
                          [-88.269, -324.49],
                          [-65.617, -356.962],
                          [-30.605, -357.406],
                          [-35.94, -325.379],
                          [-49.909, -294.542],
                          [-28.537, -270.823],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: lottieColor.value,
                      ix: 3,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4,
                    },
                    w: {
                      a: 0,
                      k: 30,
                      ix: 5,
                    },
                    lc: 2,
                    lj: 2,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: {
                      a: 0,
                      k: [411.786, 443.355],
                      ix: 2,
                    },
                    a: {
                      a: 0,
                      k: [0, 0],
                      ix: 1,
                    },
                    s: {
                      a: 0,
                      k: [100, 100],
                      ix: 3,
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7,
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4,
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5,
                    },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 0,
                  k: 0,
                  ix: 1,
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.833],
                        y: [0.833],
                      },
                      o: {
                        x: [0.167],
                        y: [0.167],
                      },
                      t: 30,
                      s: [0],
                    },
                    {
                      t: 45.0000018328876,
                      s: [100],
                    },
                  ],
                  ix: 2,
                },
                o: {
                  a: 0,
                  k: 0,
                  ix: 3,
                },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 0,
            op: 150.000006109625,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Layer 1 Outlines',
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0.833,
                      y: 0.833,
                    },
                    o: {
                      x: 0.167,
                      y: 0.167,
                    },
                    t: 10,
                    s: [511.623, 543.786, 0],
                    to: [0, -13.5, 0],
                    ti: [0, 0, 0],
                  },
                  {
                    i: {
                      x: 0.833,
                      y: 0.833,
                    },
                    o: {
                      x: 0.167,
                      y: 0.167,
                    },
                    t: 15,
                    s: [511.623, 462.786, 0],
                    to: [0, 0, 0],
                    ti: [0, -13.5, 0],
                  },
                  {
                    t: 20.0000008146167,
                    s: [511.623, 543.786, 0],
                  },
                ],
                ix: 2,
              },
              a: {
                a: 0,
                k: [411.786, 443.355, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100, 100],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [-11.259, 0.968],
                          [-49.772, 4.278],
                          [-12.165, -20.57],
                          [4.781, -21.915],
                          [-19.169, -11.647],
                          [-5.409, -14.171],
                          [-2.72, -9.3],
                          [-15.865, 4.297],
                          [-11.669, 11.576],
                          [37.651, -29.991],
                          [19.327, -20.442],
                          [6.371, -45.982],
                          [34.75, -33.286],
                          [43.413, 20.755],
                          [15.718, 24.623],
                          [3.233, 18.154],
                          [-12.271, 13.763],
                          [-5.223, 17.141],
                          [8.368, 21.747],
                          [-0.751, 23.955],
                          [23.507, 4.669],
                          [13.428, -3.797],
                          [12.747, -5.678],
                          [29.12, 22.929],
                          [-22.861, 29.174],
                          [-10.365, 12.669],
                          [-7.851, 26.19],
                          [-27.282, -1.808],
                          [-10.093, -2.827],
                          [-17.24, 23.479],
                          [-9.824, 8.877],
                          [-7.446, -10.949],
                          [6.568, -9.478],
                          [-0.821, -11.502],
                          [-10.76, -3.454],
                        ],
                        o: [
                          [49.772, -4.278],
                          [23.809, -2.046],
                          [-22.28, 2.604],
                          [-4.783, 21.915],
                          [12.963, 7.877],
                          [3.456, 9.052],
                          [4.614, 15.776],
                          [15.865, -4.297],
                          [14.421, 45.925],
                          [-22.005, 17.527],
                          [-31.892, 33.732],
                          [-6.605, 47.665],
                          [-34.751, 33.286],
                          [-26.355, -12.6],
                          [-9.922, -15.543],
                          [-3.234, -18.155],
                          [11.925, -13.375],
                          [6.792, -22.29],
                          [-8.607, -22.368],
                          [0.751, -23.955],
                          [-9.815, -9.92],
                          [-13.428, 3.797],
                          [-33.858, 15.079],
                          [-29.12, -22.929],
                          [10.096, -12.884],
                          [17.314, -21.162],
                          [7.851, -26.19],
                          [10.459, 0.693],
                          [28.049, 7.857],
                          [7.837, -10.672],
                          [9.824, -8.877],
                          [6.485, 9.535],
                          [-6.568, 9.478],
                          [0.805, 11.272],
                          [10.758, 3.452],
                        ],
                        v: [
                          [5.091, -269.271],
                          [154.407, -282.103],
                          [219.445, -263.097],
                          [177.818, -219.579],
                          [202.665, -161.616],
                          [238.696, -135.656],
                          [241.813, -106.901],
                          [282.413, -88.719],
                          [322.366, -116.615],
                          [285.685, 11.953],
                          [216.337, 59.638],
                          [178.702, 191.021],
                          [120.048, 319.959],
                          [-12.795, 347.6],
                          [-74.179, 286.682],
                          [-96.338, 236.391],
                          [-84.018, 184.385],
                          [-47.023, 146.46],
                          [-70.943, 82.877],
                          [-72.167, 11.624],
                          [-115.052, -46.183],
                          [-154.299, -53.29],
                          [-192.396, -36.082],
                          [-297.823, -44.852],
                          [-313.925, -143.673],
                          [-278.591, -177.655],
                          [-251.236, -254.24],
                          [-196.084, -306.273],
                          [-165.925, -297.704],
                          [-88.269, -324.49],
                          [-65.617, -356.962],
                          [-30.605, -357.406],
                          [-35.94, -325.379],
                          [-49.909, -294.542],
                          [-28.537, -270.823],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: lottieColor.value,
                      ix: 3,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4,
                    },
                    w: {
                      a: 0,
                      k: 30,
                      ix: 5,
                    },
                    lc: 2,
                    lj: 2,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: {
                      a: 0,
                      k: [411.786, 443.355],
                      ix: 2,
                    },
                    a: {
                      a: 0,
                      k: [0, 0],
                      ix: 1,
                    },
                    s: {
                      a: 0,
                      k: [100, 100],
                      ix: 3,
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7,
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4,
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5,
                    },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.833],
                        y: [0.833],
                      },
                      o: {
                        x: [0.167],
                        y: [0.167],
                      },
                      t: 15,
                      s: [0],
                    },
                    {
                      t: 30.0000012219251,
                      s: [100],
                    },
                  ],
                  ix: 1,
                },
                e: {
                  a: 0,
                  k: 100,
                  ix: 2,
                },
                o: {
                  a: 0,
                  k: 0,
                  ix: 3,
                },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 0,
            op: 150.000006109625,
            st: 0,
            bm: 0,
          },
        ],
        markers: [],
      }
    })
    const size = computed(() => props.size)
    const cssSize = computed(() => {
      if (regex.test(size.value.toString())) {
        return `${size.value}px`
      }
      return size.value
    })
    const containerStyleBind = computed(() => ({
      width: cssSize.value,
      height: cssSize.value,
    }))
    const container = ref<HTMLElement | undefined>(undefined)
    onMounted(() => {
      if (container.value) {
        lottie.loadAnimation({
          container: container.value,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: animationData.value,
        })
      }
    })
    return {
      containerStyleBind,
      container,
      lottieColor,
    }
  },
})
</script>
